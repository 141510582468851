// source: core/Tron.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('@tronweb3/google-protobuf');
var goog = jspb;
var global =
  (typeof globalThis !== 'undefined' && globalThis) ||
  (typeof window !== 'undefined' && window) ||
  (typeof global !== 'undefined' && global) ||
  (typeof self !== 'undefined' && self) ||
  (function () { return this; }).call(null) ||
  Function('return this')();

var google_protobuf_any_pb = require('@tronweb3/google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('TronWebProto.Account', null, global);
goog.exportSymbol('TronWebProto.Account.AccountResource', null, global);
goog.exportSymbol('TronWebProto.Account.Frozen', null, global);
goog.exportSymbol('TronWebProto.AccountId', null, global);
goog.exportSymbol('TronWebProto.AccountType', null, global);
goog.exportSymbol('TronWebProto.DelegatedResource', null, global);
goog.exportSymbol('TronWebProto.Exchange', null, global);
goog.exportSymbol('TronWebProto.Key', null, global);
goog.exportSymbol('TronWebProto.MarketOrderDetail', null, global);
goog.exportSymbol('TronWebProto.Permission', null, global);
goog.exportSymbol('TronWebProto.Permission.PermissionType', null, global);
goog.exportSymbol('TronWebProto.Proposal', null, global);
goog.exportSymbol('TronWebProto.Proposal.State', null, global);
goog.exportSymbol('TronWebProto.Transaction', null, global);
goog.exportSymbol('TronWebProto.Transaction.Contract', null, global);
goog.exportSymbol('TronWebProto.Transaction.Contract.ContractType', null, global);
goog.exportSymbol('TronWebProto.Transaction.Result', null, global);
goog.exportSymbol('TronWebProto.Transaction.Result.code', null, global);
goog.exportSymbol('TronWebProto.Transaction.Result.contractResult', null, global);
goog.exportSymbol('TronWebProto.Transaction.raw', null, global);
goog.exportSymbol('TronWebProto.Vote', null, global);
goog.exportSymbol('TronWebProto.Votes', null, global);
goog.exportSymbol('TronWebProto.Witness', null, global);
goog.exportSymbol('TronWebProto.authority', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AccountId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.AccountId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.AccountId.displayName = 'TronWebProto.AccountId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Vote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Vote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Vote.displayName = 'TronWebProto.Vote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Proposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Proposal.repeatedFields_, null);
};
goog.inherits(TronWebProto.Proposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Proposal.displayName = 'TronWebProto.Proposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Exchange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Exchange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Exchange.displayName = 'TronWebProto.Exchange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Account.repeatedFields_, null);
};
goog.inherits(TronWebProto.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Account.displayName = 'TronWebProto.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Account.Frozen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Account.Frozen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Account.Frozen.displayName = 'TronWebProto.Account.Frozen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Account.AccountResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Account.AccountResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Account.AccountResource.displayName = 'TronWebProto.Account.AccountResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Key.displayName = 'TronWebProto.Key';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.DelegatedResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.DelegatedResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.DelegatedResource.displayName = 'TronWebProto.DelegatedResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.authority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.authority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.authority.displayName = 'TronWebProto.authority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Permission.repeatedFields_, null);
};
goog.inherits(TronWebProto.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Permission.displayName = 'TronWebProto.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Witness = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Witness, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Witness.displayName = 'TronWebProto.Witness';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Votes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Votes.repeatedFields_, null);
};
goog.inherits(TronWebProto.Votes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Votes.displayName = 'TronWebProto.Votes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.MarketOrderDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.MarketOrderDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.MarketOrderDetail.displayName = 'TronWebProto.MarketOrderDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Transaction.repeatedFields_, null);
};
goog.inherits(TronWebProto.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Transaction.displayName = 'TronWebProto.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Transaction.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.Transaction.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Transaction.Contract.displayName = 'TronWebProto.Transaction.Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Transaction.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Transaction.Result.repeatedFields_, null);
};
goog.inherits(TronWebProto.Transaction.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Transaction.Result.displayName = 'TronWebProto.Transaction.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.Transaction.raw = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.Transaction.raw.repeatedFields_, null);
};
goog.inherits(TronWebProto.Transaction.raw, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.Transaction.raw.displayName = 'TronWebProto.Transaction.raw';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.AccountId.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.AccountId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.AccountId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AccountId.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: msg.getName_asB64(),
    address: msg.getAddress_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AccountId}
 */
TronWebProto.AccountId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.AccountId;
  return TronWebProto.AccountId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AccountId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AccountId}
 */
TronWebProto.AccountId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AccountId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.AccountId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AccountId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AccountId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes name = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AccountId.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes name = 1;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
TronWebProto.AccountId.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
TronWebProto.AccountId.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AccountId} returns this
 */
TronWebProto.AccountId.prototype.setName = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AccountId.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes address = 2;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.AccountId.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.AccountId.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AccountId} returns this
 */
TronWebProto.AccountId.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Vote.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Vote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Vote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Vote.toObject = function(includeInstance, msg) {
  var f, obj = {
    voteAddress: msg.getVoteAddress_asB64(),
    voteCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Vote}
 */
TronWebProto.Vote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Vote;
  return TronWebProto.Vote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Vote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Vote}
 */
TronWebProto.Vote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVoteAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVoteCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Vote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Vote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Vote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Vote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVoteAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getVoteCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional bytes vote_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Vote.prototype.getVoteAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes vote_address = 1;
 * This is a type-conversion wrapper around `getVoteAddress()`
 * @return {string}
 */
TronWebProto.Vote.prototype.getVoteAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVoteAddress()));
};


/**
 * optional bytes vote_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVoteAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Vote.prototype.getVoteAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVoteAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Vote} returns this
 */
TronWebProto.Vote.prototype.setVoteAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 vote_count = 2;
 * @return {number}
 */
TronWebProto.Vote.prototype.getVoteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Vote} returns this
 */
TronWebProto.Vote.prototype.setVoteCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Proposal.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Proposal.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Proposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Proposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Proposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    proposalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    proposerAddress: msg.getProposerAddress_asB64(),
    parametersMap: (f = msg.getParametersMap()) ? f.toObject(includeInstance, undefined) : [],
    expirationTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    approvalsList: msg.getApprovalsList_asB64(),
    state: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Proposal}
 */
TronWebProto.Proposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Proposal;
  return TronWebProto.Proposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Proposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Proposal}
 */
TronWebProto.Proposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProposalId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setProposerAddress(value);
      break;
    case 3:
      var value = msg.getParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readInt64, null, 0, 0);
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateTime(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addApprovals(value);
      break;
    case 7:
      var value = /** @type {!TronWebProto.Proposal.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Proposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Proposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Proposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Proposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProposalId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProposerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getExpirationTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getApprovalsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      6,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
TronWebProto.Proposal.State = {
  PENDING: 0,
  DISAPPROVED: 1,
  APPROVED: 2,
  CANCELED: 3
};

/**
 * optional int64 proposal_id = 1;
 * @return {number}
 */
TronWebProto.Proposal.prototype.getProposalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.setProposalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes proposer_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Proposal.prototype.getProposerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes proposer_address = 2;
 * This is a type-conversion wrapper around `getProposerAddress()`
 * @return {string}
 */
TronWebProto.Proposal.prototype.getProposerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getProposerAddress()));
};


/**
 * optional bytes proposer_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getProposerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Proposal.prototype.getProposerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getProposerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.setProposerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * map<int64, int64> parameters = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
TronWebProto.Proposal.prototype.getParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.clearParametersMap = function() {
  this.getParametersMap().clear();
  return this;};


/**
 * optional int64 expiration_time = 4;
 * @return {number}
 */
TronWebProto.Proposal.prototype.getExpirationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.setExpirationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 create_time = 5;
 * @return {number}
 */
TronWebProto.Proposal.prototype.getCreateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated bytes approvals = 6;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
TronWebProto.Proposal.prototype.getApprovalsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * repeated bytes approvals = 6;
 * This is a type-conversion wrapper around `getApprovalsList()`
 * @return {!Array<string>}
 */
TronWebProto.Proposal.prototype.getApprovalsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getApprovalsList()));
};


/**
 * repeated bytes approvals = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getApprovalsList()`
 * @return {!Array<!Uint8Array>}
 */
TronWebProto.Proposal.prototype.getApprovalsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getApprovalsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.setApprovalsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.addApprovals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.clearApprovalsList = function() {
  return this.setApprovalsList([]);
};


/**
 * optional State state = 7;
 * @return {!TronWebProto.Proposal.State}
 */
TronWebProto.Proposal.prototype.getState = function() {
  return /** @type {!TronWebProto.Proposal.State} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!TronWebProto.Proposal.State} value
 * @return {!TronWebProto.Proposal} returns this
 */
TronWebProto.Proposal.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Exchange.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Exchange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Exchange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Exchange.toObject = function(includeInstance, msg) {
  var f, obj = {
    exchangeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creatorAddress: msg.getCreatorAddress_asB64(),
    createTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    firstTokenId: msg.getFirstTokenId_asB64(),
    firstTokenBalance: jspb.Message.getFieldWithDefault(msg, 7, 0),
    secondTokenId: msg.getSecondTokenId_asB64(),
    secondTokenBalance: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Exchange}
 */
TronWebProto.Exchange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Exchange;
  return TronWebProto.Exchange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Exchange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Exchange}
 */
TronWebProto.Exchange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExchangeId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCreatorAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateTime(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFirstTokenId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstTokenBalance(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecondTokenId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSecondTokenBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Exchange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Exchange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Exchange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Exchange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExchangeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreatorAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getCreateTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFirstTokenId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getFirstTokenBalance();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSecondTokenId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getSecondTokenBalance();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional int64 exchange_id = 1;
 * @return {number}
 */
TronWebProto.Exchange.prototype.getExchangeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setExchangeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes creator_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Exchange.prototype.getCreatorAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes creator_address = 2;
 * This is a type-conversion wrapper around `getCreatorAddress()`
 * @return {string}
 */
TronWebProto.Exchange.prototype.getCreatorAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCreatorAddress()));
};


/**
 * optional bytes creator_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCreatorAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Exchange.prototype.getCreatorAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCreatorAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setCreatorAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional int64 create_time = 3;
 * @return {number}
 */
TronWebProto.Exchange.prototype.getCreateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes first_token_id = 6;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Exchange.prototype.getFirstTokenId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes first_token_id = 6;
 * This is a type-conversion wrapper around `getFirstTokenId()`
 * @return {string}
 */
TronWebProto.Exchange.prototype.getFirstTokenId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFirstTokenId()));
};


/**
 * optional bytes first_token_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFirstTokenId()`
 * @return {!Uint8Array}
 */
TronWebProto.Exchange.prototype.getFirstTokenId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFirstTokenId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setFirstTokenId = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional int64 first_token_balance = 7;
 * @return {number}
 */
TronWebProto.Exchange.prototype.getFirstTokenBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setFirstTokenBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bytes second_token_id = 8;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Exchange.prototype.getSecondTokenId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes second_token_id = 8;
 * This is a type-conversion wrapper around `getSecondTokenId()`
 * @return {string}
 */
TronWebProto.Exchange.prototype.getSecondTokenId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecondTokenId()));
};


/**
 * optional bytes second_token_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecondTokenId()`
 * @return {!Uint8Array}
 */
TronWebProto.Exchange.prototype.getSecondTokenId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecondTokenId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setSecondTokenId = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional int64 second_token_balance = 9;
 * @return {number}
 */
TronWebProto.Exchange.prototype.getSecondTokenBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Exchange} returns this
 */
TronWebProto.Exchange.prototype.setSecondTokenBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Account.repeatedFields_ = [5,7,16,33];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Account.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountName: msg.getAccountName_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    address: msg.getAddress_asB64(),
    balance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    votesList: jspb.Message.toObjectList(msg.getVotesList(),
    TronWebProto.Vote.toObject, includeInstance),
    assetMap: (f = msg.getAssetMap()) ? f.toObject(includeInstance, undefined) : [],
    assetv2Map: (f = msg.getAssetv2Map()) ? f.toObject(includeInstance, undefined) : [],
    frozenList: jspb.Message.toObjectList(msg.getFrozenList(),
    TronWebProto.Account.Frozen.toObject, includeInstance),
    netUsage: jspb.Message.getFieldWithDefault(msg, 8, 0),
    acquiredDelegatedFrozenBalanceForBandwidth: jspb.Message.getFieldWithDefault(msg, 41, 0),
    delegatedFrozenBalanceForBandwidth: jspb.Message.getFieldWithDefault(msg, 42, 0),
    oldTronPower: jspb.Message.getFieldWithDefault(msg, 46, 0),
    tronPower: (f = msg.getTronPower()) && TronWebProto.Account.Frozen.toObject(includeInstance, f),
    assetOptimized: jspb.Message.getBooleanFieldWithDefault(msg, 60, false),
    createTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    latestOprationTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    allowance: jspb.Message.getFieldWithDefault(msg, 11, 0),
    latestWithdrawTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    code: msg.getCode_asB64(),
    isWitness: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isCommittee: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    frozenSupplyList: jspb.Message.toObjectList(msg.getFrozenSupplyList(),
    TronWebProto.Account.Frozen.toObject, includeInstance),
    assetIssuedName: msg.getAssetIssuedName_asB64(),
    assetIssuedId: msg.getAssetIssuedId_asB64(),
    latestAssetOperationTimeMap: (f = msg.getLatestAssetOperationTimeMap()) ? f.toObject(includeInstance, undefined) : [],
    latestAssetOperationTimev2Map: (f = msg.getLatestAssetOperationTimev2Map()) ? f.toObject(includeInstance, undefined) : [],
    freeNetUsage: jspb.Message.getFieldWithDefault(msg, 19, 0),
    freeAssetNetUsageMap: (f = msg.getFreeAssetNetUsageMap()) ? f.toObject(includeInstance, undefined) : [],
    freeAssetNetUsagev2Map: (f = msg.getFreeAssetNetUsagev2Map()) ? f.toObject(includeInstance, undefined) : [],
    latestConsumeTime: jspb.Message.getFieldWithDefault(msg, 21, 0),
    latestConsumeFreeTime: jspb.Message.getFieldWithDefault(msg, 22, 0),
    accountId: msg.getAccountId_asB64(),
    accountResource: (f = msg.getAccountResource()) && TronWebProto.Account.AccountResource.toObject(includeInstance, f),
    codehash: msg.getCodehash_asB64(),
    ownerPermission: (f = msg.getOwnerPermission()) && TronWebProto.Permission.toObject(includeInstance, f),
    witnessPermission: (f = msg.getWitnessPermission()) && TronWebProto.Permission.toObject(includeInstance, f),
    activePermissionList: jspb.Message.toObjectList(msg.getActivePermissionList(),
    TronWebProto.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Account}
 */
TronWebProto.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Account;
  return TronWebProto.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Account}
 */
TronWebProto.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccountName(value);
      break;
    case 2:
      var value = /** @type {!TronWebProto.AccountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBalance(value);
      break;
    case 5:
      var value = new TronWebProto.Vote;
      reader.readMessage(value,TronWebProto.Vote.deserializeBinaryFromReader);
      msg.addVotes(value);
      break;
    case 6:
      var value = msg.getAssetMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 56:
      var value = msg.getAssetv2Map();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 7:
      var value = new TronWebProto.Account.Frozen;
      reader.readMessage(value,TronWebProto.Account.Frozen.deserializeBinaryFromReader);
      msg.addFrozen(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNetUsage(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcquiredDelegatedFrozenBalanceForBandwidth(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelegatedFrozenBalanceForBandwidth(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOldTronPower(value);
      break;
    case 47:
      var value = new TronWebProto.Account.Frozen;
      reader.readMessage(value,TronWebProto.Account.Frozen.deserializeBinaryFromReader);
      msg.setTronPower(value);
      break;
    case 60:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssetOptimized(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestOprationTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAllowance(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestWithdrawTime(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCode(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWitness(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCommittee(value);
      break;
    case 16:
      var value = new TronWebProto.Account.Frozen;
      reader.readMessage(value,TronWebProto.Account.Frozen.deserializeBinaryFromReader);
      msg.addFrozenSupply(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAssetIssuedName(value);
      break;
    case 57:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAssetIssuedId(value);
      break;
    case 18:
      var value = msg.getLatestAssetOperationTimeMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 58:
      var value = msg.getLatestAssetOperationTimev2Map();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFreeNetUsage(value);
      break;
    case 20:
      var value = msg.getFreeAssetNetUsageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 59:
      var value = msg.getFreeAssetNetUsagev2Map();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestConsumeTime(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestConsumeFreeTime(value);
      break;
    case 23:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccountId(value);
      break;
    case 26:
      var value = new TronWebProto.Account.AccountResource;
      reader.readMessage(value,TronWebProto.Account.AccountResource.deserializeBinaryFromReader);
      msg.setAccountResource(value);
      break;
    case 30:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodehash(value);
      break;
    case 31:
      var value = new TronWebProto.Permission;
      reader.readMessage(value,TronWebProto.Permission.deserializeBinaryFromReader);
      msg.setOwnerPermission(value);
      break;
    case 32:
      var value = new TronWebProto.Permission;
      reader.readMessage(value,TronWebProto.Permission.deserializeBinaryFromReader);
      msg.setWitnessPermission(value);
      break;
    case 33:
      var value = new TronWebProto.Permission;
      reader.readMessage(value,TronWebProto.Permission.deserializeBinaryFromReader);
      msg.addActivePermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      TronWebProto.Vote.serializeBinaryToWriter
    );
  }
  f = message.getAssetMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getAssetv2Map(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(56, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getFrozenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      TronWebProto.Account.Frozen.serializeBinaryToWriter
    );
  }
  f = message.getNetUsage();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAcquiredDelegatedFrozenBalanceForBandwidth();
  if (f !== 0) {
    writer.writeInt64(
      41,
      f
    );
  }
  f = message.getDelegatedFrozenBalanceForBandwidth();
  if (f !== 0) {
    writer.writeInt64(
      42,
      f
    );
  }
  f = message.getOldTronPower();
  if (f !== 0) {
    writer.writeInt64(
      46,
      f
    );
  }
  f = message.getTronPower();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      TronWebProto.Account.Frozen.serializeBinaryToWriter
    );
  }
  f = message.getAssetOptimized();
  if (f) {
    writer.writeBool(
      60,
      f
    );
  }
  f = message.getCreateTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getLatestOprationTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getAllowance();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getLatestWithdrawTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getIsWitness();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsCommittee();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getFrozenSupplyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      TronWebProto.Account.Frozen.serializeBinaryToWriter
    );
  }
  f = message.getAssetIssuedName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getAssetIssuedId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      57,
      f
    );
  }
  f = message.getLatestAssetOperationTimeMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getLatestAssetOperationTimev2Map(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(58, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getFreeNetUsage();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getFreeAssetNetUsageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(20, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getFreeAssetNetUsagev2Map(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(59, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getLatestConsumeTime();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getLatestConsumeFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getAccountId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      23,
      f
    );
  }
  f = message.getAccountResource();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      TronWebProto.Account.AccountResource.serializeBinaryToWriter
    );
  }
  f = message.getCodehash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      30,
      f
    );
  }
  f = message.getOwnerPermission();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      TronWebProto.Permission.serializeBinaryToWriter
    );
  }
  f = message.getWitnessPermission();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      TronWebProto.Permission.serializeBinaryToWriter
    );
  }
  f = message.getActivePermissionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      TronWebProto.Permission.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Account.Frozen.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Account.Frozen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Account.Frozen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Account.Frozen.toObject = function(includeInstance, msg) {
  var f, obj = {
    frozenBalance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expireTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Account.Frozen}
 */
TronWebProto.Account.Frozen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Account.Frozen;
  return TronWebProto.Account.Frozen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Account.Frozen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Account.Frozen}
 */
TronWebProto.Account.Frozen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrozenBalance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Account.Frozen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Account.Frozen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Account.Frozen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Account.Frozen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrozenBalance();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getExpireTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 frozen_balance = 1;
 * @return {number}
 */
TronWebProto.Account.Frozen.prototype.getFrozenBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.Frozen} returns this
 */
TronWebProto.Account.Frozen.prototype.setFrozenBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 expire_time = 2;
 * @return {number}
 */
TronWebProto.Account.Frozen.prototype.getExpireTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.Frozen} returns this
 */
TronWebProto.Account.Frozen.prototype.setExpireTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Account.AccountResource.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Account.AccountResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Account.AccountResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Account.AccountResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyUsage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frozenBalanceForEnergy: (f = msg.getFrozenBalanceForEnergy()) && TronWebProto.Account.Frozen.toObject(includeInstance, f),
    latestConsumeTimeForEnergy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    acquiredDelegatedFrozenBalanceForEnergy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    delegatedFrozenBalanceForEnergy: jspb.Message.getFieldWithDefault(msg, 5, 0),
    storageLimit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    storageUsage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    latestExchangeStorageTime: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Account.AccountResource}
 */
TronWebProto.Account.AccountResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Account.AccountResource;
  return TronWebProto.Account.AccountResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Account.AccountResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Account.AccountResource}
 */
TronWebProto.Account.AccountResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnergyUsage(value);
      break;
    case 2:
      var value = new TronWebProto.Account.Frozen;
      reader.readMessage(value,TronWebProto.Account.Frozen.deserializeBinaryFromReader);
      msg.setFrozenBalanceForEnergy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestConsumeTimeForEnergy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcquiredDelegatedFrozenBalanceForEnergy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelegatedFrozenBalanceForEnergy(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStorageLimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStorageUsage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestExchangeStorageTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Account.AccountResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Account.AccountResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Account.AccountResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Account.AccountResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyUsage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFrozenBalanceForEnergy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      TronWebProto.Account.Frozen.serializeBinaryToWriter
    );
  }
  f = message.getLatestConsumeTimeForEnergy();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAcquiredDelegatedFrozenBalanceForEnergy();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDelegatedFrozenBalanceForEnergy();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStorageLimit();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStorageUsage();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLatestExchangeStorageTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 energy_usage = 1;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getEnergyUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setEnergyUsage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Frozen frozen_balance_for_energy = 2;
 * @return {?TronWebProto.Account.Frozen}
 */
TronWebProto.Account.AccountResource.prototype.getFrozenBalanceForEnergy = function() {
  return /** @type{?TronWebProto.Account.Frozen} */ (
    jspb.Message.getWrapperField(this, TronWebProto.Account.Frozen, 2));
};


/**
 * @param {?TronWebProto.Account.Frozen|undefined} value
 * @return {!TronWebProto.Account.AccountResource} returns this
*/
TronWebProto.Account.AccountResource.prototype.setFrozenBalanceForEnergy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.clearFrozenBalanceForEnergy = function() {
  return this.setFrozenBalanceForEnergy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Account.AccountResource.prototype.hasFrozenBalanceForEnergy = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 latest_consume_time_for_energy = 3;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getLatestConsumeTimeForEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setLatestConsumeTimeForEnergy = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 acquired_delegated_frozen_balance_for_energy = 4;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getAcquiredDelegatedFrozenBalanceForEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setAcquiredDelegatedFrozenBalanceForEnergy = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 delegated_frozen_balance_for_energy = 5;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getDelegatedFrozenBalanceForEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setDelegatedFrozenBalanceForEnergy = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 storage_limit = 6;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getStorageLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setStorageLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 storage_usage = 7;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getStorageUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setStorageUsage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 latest_exchange_storage_time = 8;
 * @return {number}
 */
TronWebProto.Account.AccountResource.prototype.getLatestExchangeStorageTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account.AccountResource} returns this
 */
TronWebProto.Account.AccountResource.prototype.setLatestExchangeStorageTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bytes account_name = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getAccountName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes account_name = 1;
 * This is a type-conversion wrapper around `getAccountName()`
 * @return {string}
 */
TronWebProto.Account.prototype.getAccountName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccountName()));
};


/**
 * optional bytes account_name = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccountName()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getAccountName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccountName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional AccountType type = 2;
 * @return {!TronWebProto.AccountType}
 */
TronWebProto.Account.prototype.getType = function() {
  return /** @type {!TronWebProto.AccountType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!TronWebProto.AccountType} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bytes address = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes address = 3;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.Account.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 balance = 4;
 * @return {number}
 */
TronWebProto.Account.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Vote votes = 5;
 * @return {!Array<!TronWebProto.Vote>}
 */
TronWebProto.Account.prototype.getVotesList = function() {
  return /** @type{!Array<!TronWebProto.Vote>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Vote, 5));
};


/**
 * @param {!Array<!TronWebProto.Vote>} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setVotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!TronWebProto.Vote=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Vote}
 */
TronWebProto.Account.prototype.addVotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, TronWebProto.Vote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearVotesList = function() {
  return this.setVotesList([]);
};


/**
 * map<string, int64> asset = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
TronWebProto.Account.prototype.getAssetMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearAssetMap = function() {
  this.getAssetMap().clear();
  return this;};


/**
 * map<string, int64> assetV2 = 56;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
TronWebProto.Account.prototype.getAssetv2Map = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 56, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearAssetv2Map = function() {
  this.getAssetv2Map().clear();
  return this;};


/**
 * repeated Frozen frozen = 7;
 * @return {!Array<!TronWebProto.Account.Frozen>}
 */
TronWebProto.Account.prototype.getFrozenList = function() {
  return /** @type{!Array<!TronWebProto.Account.Frozen>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Account.Frozen, 7));
};


/**
 * @param {!Array<!TronWebProto.Account.Frozen>} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setFrozenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!TronWebProto.Account.Frozen=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Account.Frozen}
 */
TronWebProto.Account.prototype.addFrozen = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, TronWebProto.Account.Frozen, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearFrozenList = function() {
  return this.setFrozenList([]);
};


/**
 * optional int64 net_usage = 8;
 * @return {number}
 */
TronWebProto.Account.prototype.getNetUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setNetUsage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 acquired_delegated_frozen_balance_for_bandwidth = 41;
 * @return {number}
 */
TronWebProto.Account.prototype.getAcquiredDelegatedFrozenBalanceForBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAcquiredDelegatedFrozenBalanceForBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional int64 delegated_frozen_balance_for_bandwidth = 42;
 * @return {number}
 */
TronWebProto.Account.prototype.getDelegatedFrozenBalanceForBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setDelegatedFrozenBalanceForBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional int64 old_tron_power = 46;
 * @return {number}
 */
TronWebProto.Account.prototype.getOldTronPower = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setOldTronPower = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional Frozen tron_power = 47;
 * @return {?TronWebProto.Account.Frozen}
 */
TronWebProto.Account.prototype.getTronPower = function() {
  return /** @type{?TronWebProto.Account.Frozen} */ (
    jspb.Message.getWrapperField(this, TronWebProto.Account.Frozen, 47));
};


/**
 * @param {?TronWebProto.Account.Frozen|undefined} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setTronPower = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearTronPower = function() {
  return this.setTronPower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Account.prototype.hasTronPower = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional bool asset_optimized = 60;
 * @return {boolean}
 */
TronWebProto.Account.prototype.getAssetOptimized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 60, false));
};


/**
 * @param {boolean} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAssetOptimized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 60, value);
};


/**
 * optional int64 create_time = 9;
 * @return {number}
 */
TronWebProto.Account.prototype.getCreateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 latest_opration_time = 10;
 * @return {number}
 */
TronWebProto.Account.prototype.getLatestOprationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setLatestOprationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 allowance = 11;
 * @return {number}
 */
TronWebProto.Account.prototype.getAllowance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAllowance = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 latest_withdraw_time = 12;
 * @return {number}
 */
TronWebProto.Account.prototype.getLatestWithdrawTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setLatestWithdrawTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bytes code = 13;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getCode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes code = 13;
 * This is a type-conversion wrapper around `getCode()`
 * @return {string}
 */
TronWebProto.Account.prototype.getCode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCode()));
};


/**
 * optional bytes code = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCode()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getCode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCode()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setCode = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bool is_witness = 14;
 * @return {boolean}
 */
TronWebProto.Account.prototype.getIsWitness = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setIsWitness = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_committee = 15;
 * @return {boolean}
 */
TronWebProto.Account.prototype.getIsCommittee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setIsCommittee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated Frozen frozen_supply = 16;
 * @return {!Array<!TronWebProto.Account.Frozen>}
 */
TronWebProto.Account.prototype.getFrozenSupplyList = function() {
  return /** @type{!Array<!TronWebProto.Account.Frozen>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Account.Frozen, 16));
};


/**
 * @param {!Array<!TronWebProto.Account.Frozen>} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setFrozenSupplyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!TronWebProto.Account.Frozen=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Account.Frozen}
 */
TronWebProto.Account.prototype.addFrozenSupply = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, TronWebProto.Account.Frozen, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearFrozenSupplyList = function() {
  return this.setFrozenSupplyList([]);
};


/**
 * optional bytes asset_issued_name = 17;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getAssetIssuedName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes asset_issued_name = 17;
 * This is a type-conversion wrapper around `getAssetIssuedName()`
 * @return {string}
 */
TronWebProto.Account.prototype.getAssetIssuedName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAssetIssuedName()));
};


/**
 * optional bytes asset_issued_name = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAssetIssuedName()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getAssetIssuedName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAssetIssuedName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAssetIssuedName = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};


/**
 * optional bytes asset_issued_ID = 57;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getAssetIssuedId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * optional bytes asset_issued_ID = 57;
 * This is a type-conversion wrapper around `getAssetIssuedId()`
 * @return {string}
 */
TronWebProto.Account.prototype.getAssetIssuedId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAssetIssuedId()));
};


/**
 * optional bytes asset_issued_ID = 57;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAssetIssuedId()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getAssetIssuedId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAssetIssuedId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAssetIssuedId = function(value) {
  return jspb.Message.setProto3BytesField(this, 57, value);
};


/**
 * map<string, int64> latest_asset_operation_time = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
TronWebProto.Account.prototype.getLatestAssetOperationTimeMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearLatestAssetOperationTimeMap = function() {
  this.getLatestAssetOperationTimeMap().clear();
  return this;};


/**
 * map<string, int64> latest_asset_operation_timeV2 = 58;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
TronWebProto.Account.prototype.getLatestAssetOperationTimev2Map = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 58, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearLatestAssetOperationTimev2Map = function() {
  this.getLatestAssetOperationTimev2Map().clear();
  return this;};


/**
 * optional int64 free_net_usage = 19;
 * @return {number}
 */
TronWebProto.Account.prototype.getFreeNetUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setFreeNetUsage = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * map<string, int64> free_asset_net_usage = 20;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
TronWebProto.Account.prototype.getFreeAssetNetUsageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 20, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearFreeAssetNetUsageMap = function() {
  this.getFreeAssetNetUsageMap().clear();
  return this;};


/**
 * map<string, int64> free_asset_net_usageV2 = 59;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
TronWebProto.Account.prototype.getFreeAssetNetUsagev2Map = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 59, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearFreeAssetNetUsagev2Map = function() {
  this.getFreeAssetNetUsagev2Map().clear();
  return this;};


/**
 * optional int64 latest_consume_time = 21;
 * @return {number}
 */
TronWebProto.Account.prototype.getLatestConsumeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setLatestConsumeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 latest_consume_free_time = 22;
 * @return {number}
 */
TronWebProto.Account.prototype.getLatestConsumeFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setLatestConsumeFreeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bytes account_id = 23;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getAccountId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * optional bytes account_id = 23;
 * This is a type-conversion wrapper around `getAccountId()`
 * @return {string}
 */
TronWebProto.Account.prototype.getAccountId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccountId()));
};


/**
 * optional bytes account_id = 23;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccountId()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getAccountId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccountId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3BytesField(this, 23, value);
};


/**
 * optional AccountResource account_resource = 26;
 * @return {?TronWebProto.Account.AccountResource}
 */
TronWebProto.Account.prototype.getAccountResource = function() {
  return /** @type{?TronWebProto.Account.AccountResource} */ (
    jspb.Message.getWrapperField(this, TronWebProto.Account.AccountResource, 26));
};


/**
 * @param {?TronWebProto.Account.AccountResource|undefined} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setAccountResource = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearAccountResource = function() {
  return this.setAccountResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Account.prototype.hasAccountResource = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional bytes codeHash = 30;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Account.prototype.getCodehash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * optional bytes codeHash = 30;
 * This is a type-conversion wrapper around `getCodehash()`
 * @return {string}
 */
TronWebProto.Account.prototype.getCodehash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodehash()));
};


/**
 * optional bytes codeHash = 30;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodehash()`
 * @return {!Uint8Array}
 */
TronWebProto.Account.prototype.getCodehash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodehash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.setCodehash = function(value) {
  return jspb.Message.setProto3BytesField(this, 30, value);
};


/**
 * optional Permission owner_permission = 31;
 * @return {?TronWebProto.Permission}
 */
TronWebProto.Account.prototype.getOwnerPermission = function() {
  return /** @type{?TronWebProto.Permission} */ (
    jspb.Message.getWrapperField(this, TronWebProto.Permission, 31));
};


/**
 * @param {?TronWebProto.Permission|undefined} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setOwnerPermission = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearOwnerPermission = function() {
  return this.setOwnerPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Account.prototype.hasOwnerPermission = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional Permission witness_permission = 32;
 * @return {?TronWebProto.Permission}
 */
TronWebProto.Account.prototype.getWitnessPermission = function() {
  return /** @type{?TronWebProto.Permission} */ (
    jspb.Message.getWrapperField(this, TronWebProto.Permission, 32));
};


/**
 * @param {?TronWebProto.Permission|undefined} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setWitnessPermission = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearWitnessPermission = function() {
  return this.setWitnessPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Account.prototype.hasWitnessPermission = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * repeated Permission active_permission = 33;
 * @return {!Array<!TronWebProto.Permission>}
 */
TronWebProto.Account.prototype.getActivePermissionList = function() {
  return /** @type{!Array<!TronWebProto.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Permission, 33));
};


/**
 * @param {!Array<!TronWebProto.Permission>} value
 * @return {!TronWebProto.Account} returns this
*/
TronWebProto.Account.prototype.setActivePermissionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!TronWebProto.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Permission}
 */
TronWebProto.Account.prototype.addActivePermission = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, TronWebProto.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Account} returns this
 */
TronWebProto.Account.prototype.clearActivePermissionList = function() {
  return this.setActivePermissionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Key.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: msg.getAddress_asB64(),
    weight: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Key}
 */
TronWebProto.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Key;
  return TronWebProto.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Key}
 */
TronWebProto.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional bytes address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Key.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes address = 1;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.Key.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Key.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Key} returns this
 */
TronWebProto.Key.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 weight = 2;
 * @return {number}
 */
TronWebProto.Key.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Key} returns this
 */
TronWebProto.Key.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.DelegatedResource.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.DelegatedResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.DelegatedResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.DelegatedResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: msg.getFrom_asB64(),
    to: msg.getTo_asB64(),
    frozenBalanceForBandwidth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frozenBalanceForEnergy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expireTimeForBandwidth: jspb.Message.getFieldWithDefault(msg, 5, 0),
    expireTimeForEnergy: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.DelegatedResource}
 */
TronWebProto.DelegatedResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.DelegatedResource;
  return TronWebProto.DelegatedResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.DelegatedResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.DelegatedResource}
 */
TronWebProto.DelegatedResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrozenBalanceForBandwidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrozenBalanceForEnergy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireTimeForBandwidth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireTimeForEnergy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.DelegatedResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.DelegatedResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.DelegatedResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.DelegatedResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTo_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFrozenBalanceForBandwidth();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFrozenBalanceForEnergy();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getExpireTimeForBandwidth();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getExpireTimeForEnergy();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional bytes from = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.DelegatedResource.prototype.getFrom = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes from = 1;
 * This is a type-conversion wrapper around `getFrom()`
 * @return {string}
 */
TronWebProto.DelegatedResource.prototype.getFrom_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFrom()));
};


/**
 * optional bytes from = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFrom()`
 * @return {!Uint8Array}
 */
TronWebProto.DelegatedResource.prototype.getFrom_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFrom()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.DelegatedResource} returns this
 */
TronWebProto.DelegatedResource.prototype.setFrom = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes to = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.DelegatedResource.prototype.getTo = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes to = 2;
 * This is a type-conversion wrapper around `getTo()`
 * @return {string}
 */
TronWebProto.DelegatedResource.prototype.getTo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTo()));
};


/**
 * optional bytes to = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTo()`
 * @return {!Uint8Array}
 */
TronWebProto.DelegatedResource.prototype.getTo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTo()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.DelegatedResource} returns this
 */
TronWebProto.DelegatedResource.prototype.setTo = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional int64 frozen_balance_for_bandwidth = 3;
 * @return {number}
 */
TronWebProto.DelegatedResource.prototype.getFrozenBalanceForBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.DelegatedResource} returns this
 */
TronWebProto.DelegatedResource.prototype.setFrozenBalanceForBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 frozen_balance_for_energy = 4;
 * @return {number}
 */
TronWebProto.DelegatedResource.prototype.getFrozenBalanceForEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.DelegatedResource} returns this
 */
TronWebProto.DelegatedResource.prototype.setFrozenBalanceForEnergy = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 expire_time_for_bandwidth = 5;
 * @return {number}
 */
TronWebProto.DelegatedResource.prototype.getExpireTimeForBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.DelegatedResource} returns this
 */
TronWebProto.DelegatedResource.prototype.setExpireTimeForBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 expire_time_for_energy = 6;
 * @return {number}
 */
TronWebProto.DelegatedResource.prototype.getExpireTimeForEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.DelegatedResource} returns this
 */
TronWebProto.DelegatedResource.prototype.setExpireTimeForEnergy = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.authority.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.authority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.authority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.authority.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && TronWebProto.AccountId.toObject(includeInstance, f),
    permissionName: msg.getPermissionName_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.authority}
 */
TronWebProto.authority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.authority;
  return TronWebProto.authority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.authority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.authority}
 */
TronWebProto.authority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new TronWebProto.AccountId;
      reader.readMessage(value,TronWebProto.AccountId.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPermissionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.authority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.authority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.authority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.authority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      TronWebProto.AccountId.serializeBinaryToWriter
    );
  }
  f = message.getPermissionName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional AccountId account = 1;
 * @return {?TronWebProto.AccountId}
 */
TronWebProto.authority.prototype.getAccount = function() {
  return /** @type{?TronWebProto.AccountId} */ (
    jspb.Message.getWrapperField(this, TronWebProto.AccountId, 1));
};


/**
 * @param {?TronWebProto.AccountId|undefined} value
 * @return {!TronWebProto.authority} returns this
*/
TronWebProto.authority.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.authority} returns this
 */
TronWebProto.authority.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.authority.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes permission_name = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.authority.prototype.getPermissionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes permission_name = 2;
 * This is a type-conversion wrapper around `getPermissionName()`
 * @return {string}
 */
TronWebProto.authority.prototype.getPermissionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPermissionName()));
};


/**
 * optional bytes permission_name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPermissionName()`
 * @return {!Uint8Array}
 */
TronWebProto.authority.prototype.getPermissionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPermissionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.authority} returns this
 */
TronWebProto.authority.prototype.setPermissionName = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Permission.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Permission.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    permissionName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threshold: jspb.Message.getFieldWithDefault(msg, 4, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    operations: msg.getOperations_asB64(),
    keysList: jspb.Message.toObjectList(msg.getKeysList(),
    TronWebProto.Key.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Permission}
 */
TronWebProto.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Permission;
  return TronWebProto.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Permission}
 */
TronWebProto.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!TronWebProto.Permission.PermissionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermissionName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreshold(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOperations(value);
      break;
    case 7:
      var value = new TronWebProto.Key;
      reader.readMessage(value,TronWebProto.Key.deserializeBinaryFromReader);
      msg.addKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPermissionName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOperations_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      TronWebProto.Key.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
TronWebProto.Permission.PermissionType = {
  OWNER: 0,
  WITNESS: 1,
  ACTIVE: 2
};

/**
 * optional PermissionType type = 1;
 * @return {!TronWebProto.Permission.PermissionType}
 */
TronWebProto.Permission.prototype.getType = function() {
  return /** @type {!TronWebProto.Permission.PermissionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!TronWebProto.Permission.PermissionType} value
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
TronWebProto.Permission.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string permission_name = 3;
 * @return {string}
 */
TronWebProto.Permission.prototype.getPermissionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.setPermissionName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 threshold = 4;
 * @return {number}
 */
TronWebProto.Permission.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 parent_id = 5;
 * @return {number}
 */
TronWebProto.Permission.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bytes operations = 6;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Permission.prototype.getOperations = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes operations = 6;
 * This is a type-conversion wrapper around `getOperations()`
 * @return {string}
 */
TronWebProto.Permission.prototype.getOperations_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOperations()));
};


/**
 * optional bytes operations = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOperations()`
 * @return {!Uint8Array}
 */
TronWebProto.Permission.prototype.getOperations_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOperations()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.setOperations = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * repeated Key keys = 7;
 * @return {!Array<!TronWebProto.Key>}
 */
TronWebProto.Permission.prototype.getKeysList = function() {
  return /** @type{!Array<!TronWebProto.Key>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Key, 7));
};


/**
 * @param {!Array<!TronWebProto.Key>} value
 * @return {!TronWebProto.Permission} returns this
*/
TronWebProto.Permission.prototype.setKeysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!TronWebProto.Key=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Key}
 */
TronWebProto.Permission.prototype.addKeys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, TronWebProto.Key, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Permission} returns this
 */
TronWebProto.Permission.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Witness.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Witness.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Witness} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Witness.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: msg.getAddress_asB64(),
    votecount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pubkey: msg.getPubkey_asB64(),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    totalproduced: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalmissed: jspb.Message.getFieldWithDefault(msg, 6, 0),
    latestblocknum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    latestslotnum: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isjobs: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Witness}
 */
TronWebProto.Witness.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Witness;
  return TronWebProto.Witness.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Witness} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Witness}
 */
TronWebProto.Witness.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVotecount(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPubkey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalproduced(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalmissed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestblocknum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestslotnum(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsjobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Witness.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Witness.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Witness} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Witness.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getVotecount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTotalproduced();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalmissed();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLatestblocknum();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLatestslotnum();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsjobs();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional bytes address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Witness.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes address = 1;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.Witness.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Witness.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 voteCount = 2;
 * @return {number}
 */
TronWebProto.Witness.prototype.getVotecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setVotecount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes pubKey = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Witness.prototype.getPubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes pubKey = 3;
 * This is a type-conversion wrapper around `getPubkey()`
 * @return {string}
 */
TronWebProto.Witness.prototype.getPubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPubkey()));
};


/**
 * optional bytes pubKey = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPubkey()`
 * @return {!Uint8Array}
 */
TronWebProto.Witness.prototype.getPubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPubkey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setPubkey = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
TronWebProto.Witness.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 totalProduced = 5;
 * @return {number}
 */
TronWebProto.Witness.prototype.getTotalproduced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setTotalproduced = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 totalMissed = 6;
 * @return {number}
 */
TronWebProto.Witness.prototype.getTotalmissed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setTotalmissed = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 latestBlockNum = 7;
 * @return {number}
 */
TronWebProto.Witness.prototype.getLatestblocknum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setLatestblocknum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 latestSlotNum = 8;
 * @return {number}
 */
TronWebProto.Witness.prototype.getLatestslotnum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setLatestslotnum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool isJobs = 9;
 * @return {boolean}
 */
TronWebProto.Witness.prototype.getIsjobs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!TronWebProto.Witness} returns this
 */
TronWebProto.Witness.prototype.setIsjobs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Votes.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Votes.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Votes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Votes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Votes.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: msg.getAddress_asB64(),
    oldVotesList: jspb.Message.toObjectList(msg.getOldVotesList(),
    TronWebProto.Vote.toObject, includeInstance),
    newVotesList: jspb.Message.toObjectList(msg.getNewVotesList(),
    TronWebProto.Vote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Votes}
 */
TronWebProto.Votes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Votes;
  return TronWebProto.Votes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Votes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Votes}
 */
TronWebProto.Votes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    case 2:
      var value = new TronWebProto.Vote;
      reader.readMessage(value,TronWebProto.Vote.deserializeBinaryFromReader);
      msg.addOldVotes(value);
      break;
    case 3:
      var value = new TronWebProto.Vote;
      reader.readMessage(value,TronWebProto.Vote.deserializeBinaryFromReader);
      msg.addNewVotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Votes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Votes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Votes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Votes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getOldVotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      TronWebProto.Vote.serializeBinaryToWriter
    );
  }
  f = message.getNewVotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      TronWebProto.Vote.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Votes.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes address = 1;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.Votes.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.Votes.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Votes} returns this
 */
TronWebProto.Votes.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * repeated Vote old_votes = 2;
 * @return {!Array<!TronWebProto.Vote>}
 */
TronWebProto.Votes.prototype.getOldVotesList = function() {
  return /** @type{!Array<!TronWebProto.Vote>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Vote, 2));
};


/**
 * @param {!Array<!TronWebProto.Vote>} value
 * @return {!TronWebProto.Votes} returns this
*/
TronWebProto.Votes.prototype.setOldVotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!TronWebProto.Vote=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Vote}
 */
TronWebProto.Votes.prototype.addOldVotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, TronWebProto.Vote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Votes} returns this
 */
TronWebProto.Votes.prototype.clearOldVotesList = function() {
  return this.setOldVotesList([]);
};


/**
 * repeated Vote new_votes = 3;
 * @return {!Array<!TronWebProto.Vote>}
 */
TronWebProto.Votes.prototype.getNewVotesList = function() {
  return /** @type{!Array<!TronWebProto.Vote>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Vote, 3));
};


/**
 * @param {!Array<!TronWebProto.Vote>} value
 * @return {!TronWebProto.Votes} returns this
*/
TronWebProto.Votes.prototype.setNewVotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!TronWebProto.Vote=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Vote}
 */
TronWebProto.Votes.prototype.addNewVotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, TronWebProto.Vote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Votes} returns this
 */
TronWebProto.Votes.prototype.clearNewVotesList = function() {
  return this.setNewVotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.MarketOrderDetail.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.MarketOrderDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.MarketOrderDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.MarketOrderDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    makerorderid: msg.getMakerorderid_asB64(),
    takerorderid: msg.getTakerorderid_asB64(),
    fillsellquantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fillbuyquantity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.MarketOrderDetail}
 */
TronWebProto.MarketOrderDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.MarketOrderDetail;
  return TronWebProto.MarketOrderDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.MarketOrderDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.MarketOrderDetail}
 */
TronWebProto.MarketOrderDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMakerorderid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTakerorderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFillsellquantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFillbuyquantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.MarketOrderDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.MarketOrderDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.MarketOrderDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.MarketOrderDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMakerorderid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTakerorderid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFillsellquantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFillbuyquantity();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bytes makerOrderId = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.MarketOrderDetail.prototype.getMakerorderid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes makerOrderId = 1;
 * This is a type-conversion wrapper around `getMakerorderid()`
 * @return {string}
 */
TronWebProto.MarketOrderDetail.prototype.getMakerorderid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMakerorderid()));
};


/**
 * optional bytes makerOrderId = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMakerorderid()`
 * @return {!Uint8Array}
 */
TronWebProto.MarketOrderDetail.prototype.getMakerorderid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMakerorderid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.MarketOrderDetail} returns this
 */
TronWebProto.MarketOrderDetail.prototype.setMakerorderid = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes takerOrderId = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.MarketOrderDetail.prototype.getTakerorderid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes takerOrderId = 2;
 * This is a type-conversion wrapper around `getTakerorderid()`
 * @return {string}
 */
TronWebProto.MarketOrderDetail.prototype.getTakerorderid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTakerorderid()));
};


/**
 * optional bytes takerOrderId = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTakerorderid()`
 * @return {!Uint8Array}
 */
TronWebProto.MarketOrderDetail.prototype.getTakerorderid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTakerorderid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.MarketOrderDetail} returns this
 */
TronWebProto.MarketOrderDetail.prototype.setTakerorderid = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional int64 fillSellQuantity = 3;
 * @return {number}
 */
TronWebProto.MarketOrderDetail.prototype.getFillsellquantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.MarketOrderDetail} returns this
 */
TronWebProto.MarketOrderDetail.prototype.setFillsellquantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 fillBuyQuantity = 4;
 * @return {number}
 */
TronWebProto.MarketOrderDetail.prototype.getFillbuyquantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.MarketOrderDetail} returns this
 */
TronWebProto.MarketOrderDetail.prototype.setFillbuyquantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Transaction.repeatedFields_ = [2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Transaction.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    rawData: (f = msg.getRawData()) && TronWebProto.Transaction.raw.toObject(includeInstance, f),
    signatureList: msg.getSignatureList_asB64(),
    retList: jspb.Message.toObjectList(msg.getRetList(),
    TronWebProto.Transaction.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Transaction}
 */
TronWebProto.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Transaction;
  return TronWebProto.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Transaction}
 */
TronWebProto.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new TronWebProto.Transaction.raw;
      reader.readMessage(value,TronWebProto.Transaction.raw.deserializeBinaryFromReader);
      msg.setRawData(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addSignature(value);
      break;
    case 5:
      var value = new TronWebProto.Transaction.Result;
      reader.readMessage(value,TronWebProto.Transaction.Result.deserializeBinaryFromReader);
      msg.addRet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRawData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      TronWebProto.Transaction.raw.serializeBinaryToWriter
    );
  }
  f = message.getSignatureList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
  f = message.getRetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      TronWebProto.Transaction.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Transaction.Contract.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Transaction.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Transaction.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parameter: (f = msg.getParameter()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    provider: msg.getProvider_asB64(),
    contractname: msg.getContractname_asB64(),
    permissionId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Transaction.Contract}
 */
TronWebProto.Transaction.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Transaction.Contract;
  return TronWebProto.Transaction.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Transaction.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Transaction.Contract}
 */
TronWebProto.Transaction.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!TronWebProto.Transaction.Contract.ContractType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setParameter(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPermissionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Transaction.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Transaction.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getParameter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getProvider_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getContractname_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getPermissionId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
TronWebProto.Transaction.Contract.ContractType = {
  ACCOUNTCREATECONTRACT: 0,
  TRANSFERCONTRACT: 1,
  TRANSFERASSETCONTRACT: 2,
  VOTEASSETCONTRACT: 3,
  VOTEWITNESSCONTRACT: 4,
  WITNESSCREATECONTRACT: 5,
  ASSETISSUECONTRACT: 6,
  WITNESSUPDATECONTRACT: 8,
  PARTICIPATEASSETISSUECONTRACT: 9,
  ACCOUNTUPDATECONTRACT: 10,
  FREEZEBALANCECONTRACT: 11,
  UNFREEZEBALANCECONTRACT: 12,
  WITHDRAWBALANCECONTRACT: 13,
  UNFREEZEASSETCONTRACT: 14,
  UPDATEASSETCONTRACT: 15,
  PROPOSALCREATECONTRACT: 16,
  PROPOSALAPPROVECONTRACT: 17,
  PROPOSALDELETECONTRACT: 18,
  SETACCOUNTIDCONTRACT: 19,
  CUSTOMCONTRACT: 20,
  CREATESMARTCONTRACT: 30,
  TRIGGERSMARTCONTRACT: 31,
  GETCONTRACT: 32,
  UPDATESETTINGCONTRACT: 33,
  EXCHANGECREATECONTRACT: 41,
  EXCHANGEINJECTCONTRACT: 42,
  EXCHANGEWITHDRAWCONTRACT: 43,
  EXCHANGETRANSACTIONCONTRACT: 44,
  UPDATEENERGYLIMITCONTRACT: 45,
  ACCOUNTPERMISSIONUPDATECONTRACT: 46,
  CLEARABICONTRACT: 48,
  UPDATEBROKERAGECONTRACT: 49,
  SHIELDEDTRANSFERCONTRACT: 51,
  MARKETSELLASSETCONTRACT: 52,
  MARKETCANCELORDERCONTRACT: 53,
  FREEZEBALANCEV2CONTRACT: 54,
  UNFREEZEBALANCEV2CONTRACT: 55,
  WITHDRAWEXPIREUNFREEZECONTRACT: 56,
  DELEGATERESOURCECONTRACT: 57,
  UNDELEGATERESOURCECONTRACT: 58,
  CANCELALLUNFREEZEV2CONTRACT: 59
};

/**
 * optional ContractType type = 1;
 * @return {!TronWebProto.Transaction.Contract.ContractType}
 */
TronWebProto.Transaction.Contract.prototype.getType = function() {
  return /** @type {!TronWebProto.Transaction.Contract.ContractType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!TronWebProto.Transaction.Contract.ContractType} value
 * @return {!TronWebProto.Transaction.Contract} returns this
 */
TronWebProto.Transaction.Contract.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Any parameter = 2;
 * @return {?TronWebProto.Any}
 */
TronWebProto.Transaction.Contract.prototype.getParameter = function() {
  return /** @type{?TronWebProto.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?TronWebProto.Any|undefined} value
 * @return {!TronWebProto.Transaction.Contract} returns this
*/
TronWebProto.Transaction.Contract.prototype.setParameter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Transaction.Contract} returns this
 */
TronWebProto.Transaction.Contract.prototype.clearParameter = function() {
  return this.setParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Transaction.Contract.prototype.hasParameter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes provider = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.Contract.prototype.getProvider = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes provider = 3;
 * This is a type-conversion wrapper around `getProvider()`
 * @return {string}
 */
TronWebProto.Transaction.Contract.prototype.getProvider_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getProvider()));
};


/**
 * optional bytes provider = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getProvider()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.Contract.prototype.getProvider_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getProvider()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.Contract} returns this
 */
TronWebProto.Transaction.Contract.prototype.setProvider = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes ContractName = 4;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.Contract.prototype.getContractname = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes ContractName = 4;
 * This is a type-conversion wrapper around `getContractname()`
 * @return {string}
 */
TronWebProto.Transaction.Contract.prototype.getContractname_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractname()));
};


/**
 * optional bytes ContractName = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractname()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.Contract.prototype.getContractname_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractname()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.Contract} returns this
 */
TronWebProto.Transaction.Contract.prototype.setContractname = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional int32 Permission_id = 5;
 * @return {number}
 */
TronWebProto.Transaction.Contract.prototype.getPermissionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Contract} returns this
 */
TronWebProto.Transaction.Contract.prototype.setPermissionId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Transaction.Result.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Transaction.Result.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Transaction.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Transaction.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    fee: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ret: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contractret: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assetissueid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    withdrawAmount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    unfreezeAmount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    exchangeReceivedAmount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    exchangeInjectAnotherAmount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    exchangeWithdrawAnotherAmount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    exchangeId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    shieldedTransactionFee: jspb.Message.getFieldWithDefault(msg, 22, 0),
    orderid: msg.getOrderid_asB64(),
    orderdetailsList: jspb.Message.toObjectList(msg.getOrderdetailsList(),
    TronWebProto.MarketOrderDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Transaction.Result}
 */
TronWebProto.Transaction.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Transaction.Result;
  return TronWebProto.Transaction.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Transaction.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Transaction.Result}
 */
TronWebProto.Transaction.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFee(value);
      break;
    case 2:
      var value = /** @type {!TronWebProto.Transaction.Result.code} */ (reader.readEnum());
      msg.setRet(value);
      break;
    case 3:
      var value = /** @type {!TronWebProto.Transaction.Result.contractResult} */ (reader.readEnum());
      msg.setContractret(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetissueid(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWithdrawAmount(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnfreezeAmount(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExchangeReceivedAmount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExchangeInjectAnotherAmount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExchangeWithdrawAnotherAmount(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExchangeId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShieldedTransactionFee(value);
      break;
    case 25:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrderid(value);
      break;
    case 26:
      var value = new TronWebProto.MarketOrderDetail;
      reader.readMessage(value,TronWebProto.MarketOrderDetail.deserializeBinaryFromReader);
      msg.addOrderdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Transaction.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Transaction.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFee();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRet();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getContractret();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAssetissueid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getWithdrawAmount();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getUnfreezeAmount();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getExchangeReceivedAmount();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getExchangeInjectAnotherAmount();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getExchangeWithdrawAnotherAmount();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getExchangeId();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getShieldedTransactionFee();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getOrderid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      25,
      f
    );
  }
  f = message.getOrderdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      TronWebProto.MarketOrderDetail.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
TronWebProto.Transaction.Result.code = {
  SUCESS: 0,
  FAILED: 1
};

/**
 * @enum {number}
 */
TronWebProto.Transaction.Result.contractResult = {
  DEFAULT: 0,
  SUCCESS: 1,
  REVERT: 2,
  BAD_JUMP_DESTINATION: 3,
  OUT_OF_MEMORY: 4,
  PRECOMPILED_CONTRACT: 5,
  STACK_TOO_SMALL: 6,
  STACK_TOO_LARGE: 7,
  ILLEGAL_OPERATION: 8,
  STACK_OVERFLOW: 9,
  OUT_OF_ENERGY: 10,
  OUT_OF_TIME: 11,
  JVM_STACK_OVER_FLOW: 12,
  UNKNOWN: 13,
  TRANSFER_FAILED: 14,
  INVALID_CODE: 15
};

/**
 * optional int64 fee = 1;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setFee = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional code ret = 2;
 * @return {!TronWebProto.Transaction.Result.code}
 */
TronWebProto.Transaction.Result.prototype.getRet = function() {
  return /** @type {!TronWebProto.Transaction.Result.code} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!TronWebProto.Transaction.Result.code} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setRet = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional contractResult contractRet = 3;
 * @return {!TronWebProto.Transaction.Result.contractResult}
 */
TronWebProto.Transaction.Result.prototype.getContractret = function() {
  return /** @type {!TronWebProto.Transaction.Result.contractResult} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!TronWebProto.Transaction.Result.contractResult} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setContractret = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string assetIssueID = 14;
 * @return {string}
 */
TronWebProto.Transaction.Result.prototype.getAssetissueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setAssetissueid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 withdraw_amount = 15;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getWithdrawAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setWithdrawAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 unfreeze_amount = 16;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getUnfreezeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setUnfreezeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 exchange_received_amount = 18;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getExchangeReceivedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setExchangeReceivedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 exchange_inject_another_amount = 19;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getExchangeInjectAnotherAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setExchangeInjectAnotherAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 exchange_withdraw_another_amount = 20;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getExchangeWithdrawAnotherAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setExchangeWithdrawAnotherAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 exchange_id = 21;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getExchangeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setExchangeId = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 shielded_transaction_fee = 22;
 * @return {number}
 */
TronWebProto.Transaction.Result.prototype.getShieldedTransactionFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setShieldedTransactionFee = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bytes orderId = 25;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.Result.prototype.getOrderid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * optional bytes orderId = 25;
 * This is a type-conversion wrapper around `getOrderid()`
 * @return {string}
 */
TronWebProto.Transaction.Result.prototype.getOrderid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrderid()));
};


/**
 * optional bytes orderId = 25;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrderid()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.Result.prototype.getOrderid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrderid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3BytesField(this, 25, value);
};


/**
 * repeated MarketOrderDetail orderDetails = 26;
 * @return {!Array<!TronWebProto.MarketOrderDetail>}
 */
TronWebProto.Transaction.Result.prototype.getOrderdetailsList = function() {
  return /** @type{!Array<!TronWebProto.MarketOrderDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.MarketOrderDetail, 26));
};


/**
 * @param {!Array<!TronWebProto.MarketOrderDetail>} value
 * @return {!TronWebProto.Transaction.Result} returns this
*/
TronWebProto.Transaction.Result.prototype.setOrderdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!TronWebProto.MarketOrderDetail=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.MarketOrderDetail}
 */
TronWebProto.Transaction.Result.prototype.addOrderdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, TronWebProto.MarketOrderDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Transaction.Result} returns this
 */
TronWebProto.Transaction.Result.prototype.clearOrderdetailsList = function() {
  return this.setOrderdetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.Transaction.raw.repeatedFields_ = [9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.Transaction.raw.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.Transaction.raw.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.Transaction.raw} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.raw.toObject = function(includeInstance, msg) {
  var f, obj = {
    refBlockBytes: msg.getRefBlockBytes_asB64(),
    refBlockNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    refBlockHash: msg.getRefBlockHash_asB64(),
    expiration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    authsList: jspb.Message.toObjectList(msg.getAuthsList(),
    TronWebProto.authority.toObject, includeInstance),
    data: msg.getData_asB64(),
    contractList: jspb.Message.toObjectList(msg.getContractList(),
    TronWebProto.Transaction.Contract.toObject, includeInstance),
    scripts: msg.getScripts_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 14, 0),
    feeLimit: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.Transaction.raw}
 */
TronWebProto.Transaction.raw.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.Transaction.raw;
  return TronWebProto.Transaction.raw.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.Transaction.raw} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.Transaction.raw}
 */
TronWebProto.Transaction.raw.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRefBlockBytes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRefBlockNum(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRefBlockHash(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiration(value);
      break;
    case 9:
      var value = new TronWebProto.authority;
      reader.readMessage(value,TronWebProto.authority.deserializeBinaryFromReader);
      msg.addAuths(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 11:
      var value = new TronWebProto.Transaction.Contract;
      reader.readMessage(value,TronWebProto.Transaction.Contract.deserializeBinaryFromReader);
      msg.addContract(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setScripts(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeeLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.raw.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.Transaction.raw.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.Transaction.raw} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.Transaction.raw.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefBlockBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getRefBlockNum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRefBlockHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getExpiration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAuthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      TronWebProto.authority.serializeBinaryToWriter
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = message.getContractList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      TronWebProto.Transaction.Contract.serializeBinaryToWriter
    );
  }
  f = message.getScripts_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFeeLimit();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional bytes ref_block_bytes = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes ref_block_bytes = 1;
 * This is a type-conversion wrapper around `getRefBlockBytes()`
 * @return {string}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRefBlockBytes()));
};


/**
 * optional bytes ref_block_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRefBlockBytes()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRefBlockBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setRefBlockBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 ref_block_num = 3;
 * @return {number}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setRefBlockNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes ref_block_hash = 4;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes ref_block_hash = 4;
 * This is a type-conversion wrapper around `getRefBlockHash()`
 * @return {string}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRefBlockHash()));
};


/**
 * optional bytes ref_block_hash = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRefBlockHash()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.raw.prototype.getRefBlockHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRefBlockHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setRefBlockHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional int64 expiration = 8;
 * @return {number}
 */
TronWebProto.Transaction.raw.prototype.getExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated authority auths = 9;
 * @return {!Array<!TronWebProto.authority>}
 */
TronWebProto.Transaction.raw.prototype.getAuthsList = function() {
  return /** @type{!Array<!TronWebProto.authority>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.authority, 9));
};


/**
 * @param {!Array<!TronWebProto.authority>} value
 * @return {!TronWebProto.Transaction.raw} returns this
*/
TronWebProto.Transaction.raw.prototype.setAuthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!TronWebProto.authority=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.authority}
 */
TronWebProto.Transaction.raw.prototype.addAuths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, TronWebProto.authority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.clearAuthsList = function() {
  return this.setAuthsList([]);
};


/**
 * optional bytes data = 10;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.raw.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes data = 10;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
TronWebProto.Transaction.raw.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.raw.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};


/**
 * repeated Contract contract = 11;
 * @return {!Array<!TronWebProto.Transaction.Contract>}
 */
TronWebProto.Transaction.raw.prototype.getContractList = function() {
  return /** @type{!Array<!TronWebProto.Transaction.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Transaction.Contract, 11));
};


/**
 * @param {!Array<!TronWebProto.Transaction.Contract>} value
 * @return {!TronWebProto.Transaction.raw} returns this
*/
TronWebProto.Transaction.raw.prototype.setContractList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!TronWebProto.Transaction.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Transaction.Contract}
 */
TronWebProto.Transaction.raw.prototype.addContract = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, TronWebProto.Transaction.Contract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.clearContractList = function() {
  return this.setContractList([]);
};


/**
 * optional bytes scripts = 12;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.Transaction.raw.prototype.getScripts = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes scripts = 12;
 * This is a type-conversion wrapper around `getScripts()`
 * @return {string}
 */
TronWebProto.Transaction.raw.prototype.getScripts_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getScripts()));
};


/**
 * optional bytes scripts = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getScripts()`
 * @return {!Uint8Array}
 */
TronWebProto.Transaction.raw.prototype.getScripts_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getScripts()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setScripts = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};


/**
 * optional int64 timestamp = 14;
 * @return {number}
 */
TronWebProto.Transaction.raw.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 fee_limit = 18;
 * @return {number}
 */
TronWebProto.Transaction.raw.prototype.getFeeLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.Transaction.raw} returns this
 */
TronWebProto.Transaction.raw.prototype.setFeeLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional raw raw_data = 1;
 * @return {?TronWebProto.Transaction.raw}
 */
TronWebProto.Transaction.prototype.getRawData = function() {
  return /** @type{?TronWebProto.Transaction.raw} */ (
    jspb.Message.getWrapperField(this, TronWebProto.Transaction.raw, 1));
};


/**
 * @param {?TronWebProto.Transaction.raw|undefined} value
 * @return {!TronWebProto.Transaction} returns this
*/
TronWebProto.Transaction.prototype.setRawData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.Transaction} returns this
 */
TronWebProto.Transaction.prototype.clearRawData = function() {
  return this.setRawData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.Transaction.prototype.hasRawData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated bytes signature = 2;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
TronWebProto.Transaction.prototype.getSignatureList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes signature = 2;
 * This is a type-conversion wrapper around `getSignatureList()`
 * @return {!Array<string>}
 */
TronWebProto.Transaction.prototype.getSignatureList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getSignatureList()));
};


/**
 * repeated bytes signature = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignatureList()`
 * @return {!Array<!Uint8Array>}
 */
TronWebProto.Transaction.prototype.getSignatureList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getSignatureList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!TronWebProto.Transaction} returns this
 */
TronWebProto.Transaction.prototype.setSignatureList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!TronWebProto.Transaction} returns this
 */
TronWebProto.Transaction.prototype.addSignature = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Transaction} returns this
 */
TronWebProto.Transaction.prototype.clearSignatureList = function() {
  return this.setSignatureList([]);
};


/**
 * repeated Result ret = 5;
 * @return {!Array<!TronWebProto.Transaction.Result>}
 */
TronWebProto.Transaction.prototype.getRetList = function() {
  return /** @type{!Array<!TronWebProto.Transaction.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.Transaction.Result, 5));
};


/**
 * @param {!Array<!TronWebProto.Transaction.Result>} value
 * @return {!TronWebProto.Transaction} returns this
*/
TronWebProto.Transaction.prototype.setRetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!TronWebProto.Transaction.Result=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.Transaction.Result}
 */
TronWebProto.Transaction.prototype.addRet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, TronWebProto.Transaction.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.Transaction} returns this
 */
TronWebProto.Transaction.prototype.clearRetList = function() {
  return this.setRetList([]);
};


/**
 * @enum {number}
 */
TronWebProto.AccountType = {
  NORMAL: 0,
  ASSETISSUE: 1,
  CONTRACT: 2
};

goog.object.extend(exports, TronWebProto);
