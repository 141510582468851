// source: core/contract/asset_issue_contract.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('@tronweb3/google-protobuf');
var goog = jspb;
var global =
  (typeof globalThis !== 'undefined' && globalThis) ||
  (typeof window !== 'undefined' && window) ||
  (typeof global !== 'undefined' && global) ||
  (typeof self !== 'undefined' && self) ||
  (function () { return this; }).call(null) ||
  Function('return this')();

goog.exportSymbol('TronWebProto.AssetIssueContract', null, global);
goog.exportSymbol('TronWebProto.AssetIssueContract.FrozenSupply', null, global);
goog.exportSymbol('TronWebProto.ParticipateAssetIssueContract', null, global);
goog.exportSymbol('TronWebProto.TransferAssetContract', null, global);
goog.exportSymbol('TronWebProto.UnfreezeAssetContract', null, global);
goog.exportSymbol('TronWebProto.UpdateAssetContract', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AssetIssueContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.AssetIssueContract.repeatedFields_, null);
};
goog.inherits(TronWebProto.AssetIssueContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.AssetIssueContract.displayName = 'TronWebProto.AssetIssueContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AssetIssueContract.FrozenSupply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.AssetIssueContract.FrozenSupply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.AssetIssueContract.FrozenSupply.displayName = 'TronWebProto.AssetIssueContract.FrozenSupply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.TransferAssetContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.TransferAssetContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.TransferAssetContract.displayName = 'TronWebProto.TransferAssetContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UnfreezeAssetContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UnfreezeAssetContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.UnfreezeAssetContract.displayName = 'TronWebProto.UnfreezeAssetContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UpdateAssetContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UpdateAssetContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.UpdateAssetContract.displayName = 'TronWebProto.UpdateAssetContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.ParticipateAssetIssueContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.ParticipateAssetIssueContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  TronWebProto.ParticipateAssetIssueContract.displayName = 'TronWebProto.ParticipateAssetIssueContract';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.AssetIssueContract.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.AssetIssueContract.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.AssetIssueContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.AssetIssueContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AssetIssueContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 41, ""),
    ownerAddress: msg.getOwnerAddress_asB64(),
    name: msg.getName_asB64(),
    abbr: msg.getAbbr_asB64(),
    totalSupply: jspb.Message.getFieldWithDefault(msg, 4, 0),
    frozenSupplyList: jspb.Message.toObjectList(msg.getFrozenSupplyList(),
    TronWebProto.AssetIssueContract.FrozenSupply.toObject, includeInstance),
    trxNum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    precision: jspb.Message.getFieldWithDefault(msg, 7, 0),
    num: jspb.Message.getFieldWithDefault(msg, 8, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    order: jspb.Message.getFieldWithDefault(msg, 11, 0),
    voteScore: jspb.Message.getFieldWithDefault(msg, 16, 0),
    description: msg.getDescription_asB64(),
    url: msg.getUrl_asB64(),
    freeAssetNetLimit: jspb.Message.getFieldWithDefault(msg, 22, 0),
    publicFreeAssetNetLimit: jspb.Message.getFieldWithDefault(msg, 23, 0),
    publicFreeAssetNetUsage: jspb.Message.getFieldWithDefault(msg, 24, 0),
    publicLatestFreeNetTime: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AssetIssueContract}
 */
TronWebProto.AssetIssueContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.AssetIssueContract;
  return TronWebProto.AssetIssueContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AssetIssueContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AssetIssueContract}
 */
TronWebProto.AssetIssueContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOwnerAddress(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAbbr(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSupply(value);
      break;
    case 5:
      var value = new TronWebProto.AssetIssueContract.FrozenSupply;
      reader.readMessage(value,TronWebProto.AssetIssueContract.FrozenSupply.deserializeBinaryFromReader);
      msg.addFrozenSupply(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrxNum(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrecision(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNum(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVoteScore(value);
      break;
    case 20:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescription(value);
      break;
    case 21:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUrl(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFreeAssetNetLimit(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublicFreeAssetNetLimit(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublicFreeAssetNetUsage(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublicLatestFreeNetTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.AssetIssueContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AssetIssueContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AssetIssueContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getOwnerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getAbbr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getTotalSupply();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFrozenSupplyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      TronWebProto.AssetIssueContract.FrozenSupply.serializeBinaryToWriter
    );
  }
  f = message.getTrxNum();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getVoteScore();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getDescription_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      20,
      f
    );
  }
  f = message.getUrl_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      21,
      f
    );
  }
  f = message.getFreeAssetNetLimit();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getPublicFreeAssetNetLimit();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getPublicFreeAssetNetUsage();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getPublicLatestFreeNetTime();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.AssetIssueContract.FrozenSupply.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.AssetIssueContract.FrozenSupply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.AssetIssueContract.FrozenSupply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AssetIssueContract.FrozenSupply.toObject = function(includeInstance, msg) {
  var f, obj = {
    frozenAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frozenDays: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AssetIssueContract.FrozenSupply}
 */
TronWebProto.AssetIssueContract.FrozenSupply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.AssetIssueContract.FrozenSupply;
  return TronWebProto.AssetIssueContract.FrozenSupply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AssetIssueContract.FrozenSupply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AssetIssueContract.FrozenSupply}
 */
TronWebProto.AssetIssueContract.FrozenSupply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrozenAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrozenDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.FrozenSupply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.AssetIssueContract.FrozenSupply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AssetIssueContract.FrozenSupply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AssetIssueContract.FrozenSupply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrozenAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFrozenDays();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 frozen_amount = 1;
 * @return {number}
 */
TronWebProto.AssetIssueContract.FrozenSupply.prototype.getFrozenAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract.FrozenSupply} returns this
 */
TronWebProto.AssetIssueContract.FrozenSupply.prototype.setFrozenAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 frozen_days = 2;
 * @return {number}
 */
TronWebProto.AssetIssueContract.FrozenSupply.prototype.getFrozenDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract.FrozenSupply} returns this
 */
TronWebProto.AssetIssueContract.FrozenSupply.prototype.setFrozenDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string id = 41;
 * @return {string}
 */
TronWebProto.AssetIssueContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AssetIssueContract.prototype.getOwnerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.AssetIssueContract.prototype.getOwnerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOwnerAddress()));
};


/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.prototype.getOwnerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOwnerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setOwnerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes name = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AssetIssueContract.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes name = 2;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
TronWebProto.AssetIssueContract.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setName = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes abbr = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AssetIssueContract.prototype.getAbbr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes abbr = 3;
 * This is a type-conversion wrapper around `getAbbr()`
 * @return {string}
 */
TronWebProto.AssetIssueContract.prototype.getAbbr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAbbr()));
};


/**
 * optional bytes abbr = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAbbr()`
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.prototype.getAbbr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAbbr()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setAbbr = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 total_supply = 4;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getTotalSupply = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setTotalSupply = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated FrozenSupply frozen_supply = 5;
 * @return {!Array<!TronWebProto.AssetIssueContract.FrozenSupply>}
 */
TronWebProto.AssetIssueContract.prototype.getFrozenSupplyList = function() {
  return /** @type{!Array<!TronWebProto.AssetIssueContract.FrozenSupply>} */ (
    jspb.Message.getRepeatedWrapperField(this, TronWebProto.AssetIssueContract.FrozenSupply, 5));
};


/**
 * @param {!Array<!TronWebProto.AssetIssueContract.FrozenSupply>} value
 * @return {!TronWebProto.AssetIssueContract} returns this
*/
TronWebProto.AssetIssueContract.prototype.setFrozenSupplyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!TronWebProto.AssetIssueContract.FrozenSupply=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.AssetIssueContract.FrozenSupply}
 */
TronWebProto.AssetIssueContract.prototype.addFrozenSupply = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, TronWebProto.AssetIssueContract.FrozenSupply, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.clearFrozenSupplyList = function() {
  return this.setFrozenSupplyList([]);
};


/**
 * optional int32 trx_num = 6;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getTrxNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setTrxNum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 precision = 7;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 num = 8;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 start_time = 9;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 end_time = 10;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 order = 11;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 vote_score = 16;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getVoteScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setVoteScore = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bytes description = 20;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AssetIssueContract.prototype.getDescription = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * optional bytes description = 20;
 * This is a type-conversion wrapper around `getDescription()`
 * @return {string}
 */
TronWebProto.AssetIssueContract.prototype.getDescription_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescription()));
};


/**
 * optional bytes description = 20;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescription()`
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.prototype.getDescription_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescription()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setDescription = function(value) {
  return jspb.Message.setProto3BytesField(this, 20, value);
};


/**
 * optional bytes url = 21;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AssetIssueContract.prototype.getUrl = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * optional bytes url = 21;
 * This is a type-conversion wrapper around `getUrl()`
 * @return {string}
 */
TronWebProto.AssetIssueContract.prototype.getUrl_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUrl()));
};


/**
 * optional bytes url = 21;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUrl()`
 * @return {!Uint8Array}
 */
TronWebProto.AssetIssueContract.prototype.getUrl_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUrl()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setUrl = function(value) {
  return jspb.Message.setProto3BytesField(this, 21, value);
};


/**
 * optional int64 free_asset_net_limit = 22;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getFreeAssetNetLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setFreeAssetNetLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 public_free_asset_net_limit = 23;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getPublicFreeAssetNetLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setPublicFreeAssetNetLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 public_free_asset_net_usage = 24;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getPublicFreeAssetNetUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setPublicFreeAssetNetUsage = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 public_latest_free_net_time = 25;
 * @return {number}
 */
TronWebProto.AssetIssueContract.prototype.getPublicLatestFreeNetTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.AssetIssueContract} returns this
 */
TronWebProto.AssetIssueContract.prototype.setPublicLatestFreeNetTime = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.TransferAssetContract.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.TransferAssetContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.TransferAssetContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.TransferAssetContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetName: msg.getAssetName_asB64(),
    ownerAddress: msg.getOwnerAddress_asB64(),
    toAddress: msg.getToAddress_asB64(),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.TransferAssetContract}
 */
TronWebProto.TransferAssetContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.TransferAssetContract;
  return TronWebProto.TransferAssetContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.TransferAssetContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.TransferAssetContract}
 */
TronWebProto.TransferAssetContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAssetName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOwnerAddress(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setToAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.TransferAssetContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.TransferAssetContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.TransferAssetContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.TransferAssetContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getOwnerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getToAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bytes asset_name = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransferAssetContract.prototype.getAssetName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes asset_name = 1;
 * This is a type-conversion wrapper around `getAssetName()`
 * @return {string}
 */
TronWebProto.TransferAssetContract.prototype.getAssetName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAssetName()));
};


/**
 * optional bytes asset_name = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAssetName()`
 * @return {!Uint8Array}
 */
TronWebProto.TransferAssetContract.prototype.getAssetName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAssetName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransferAssetContract} returns this
 */
TronWebProto.TransferAssetContract.prototype.setAssetName = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes owner_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransferAssetContract.prototype.getOwnerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes owner_address = 2;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.TransferAssetContract.prototype.getOwnerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOwnerAddress()));
};


/**
 * optional bytes owner_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TransferAssetContract.prototype.getOwnerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOwnerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransferAssetContract} returns this
 */
TronWebProto.TransferAssetContract.prototype.setOwnerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes to_address = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransferAssetContract.prototype.getToAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes to_address = 3;
 * This is a type-conversion wrapper around `getToAddress()`
 * @return {string}
 */
TronWebProto.TransferAssetContract.prototype.getToAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getToAddress()));
};


/**
 * optional bytes to_address = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TransferAssetContract.prototype.getToAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getToAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransferAssetContract} returns this
 */
TronWebProto.TransferAssetContract.prototype.setToAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
TronWebProto.TransferAssetContract.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.TransferAssetContract} returns this
 */
TronWebProto.TransferAssetContract.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.UnfreezeAssetContract.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.UnfreezeAssetContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.UnfreezeAssetContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UnfreezeAssetContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerAddress: msg.getOwnerAddress_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UnfreezeAssetContract}
 */
TronWebProto.UnfreezeAssetContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.UnfreezeAssetContract;
  return TronWebProto.UnfreezeAssetContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UnfreezeAssetContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UnfreezeAssetContract}
 */
TronWebProto.UnfreezeAssetContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOwnerAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeAssetContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.UnfreezeAssetContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UnfreezeAssetContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UnfreezeAssetContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UnfreezeAssetContract.prototype.getOwnerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UnfreezeAssetContract.prototype.getOwnerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOwnerAddress()));
};


/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeAssetContract.prototype.getOwnerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOwnerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UnfreezeAssetContract} returns this
 */
TronWebProto.UnfreezeAssetContract.prototype.setOwnerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.UpdateAssetContract.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.UpdateAssetContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.UpdateAssetContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UpdateAssetContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerAddress: msg.getOwnerAddress_asB64(),
    description: msg.getDescription_asB64(),
    url: msg.getUrl_asB64(),
    newLimit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    newPublicLimit: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UpdateAssetContract}
 */
TronWebProto.UpdateAssetContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.UpdateAssetContract;
  return TronWebProto.UpdateAssetContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UpdateAssetContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UpdateAssetContract}
 */
TronWebProto.UpdateAssetContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOwnerAddress(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewPublicLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UpdateAssetContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.UpdateAssetContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UpdateAssetContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UpdateAssetContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getDescription_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getUrl_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getNewLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNewPublicLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateAssetContract.prototype.getOwnerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UpdateAssetContract.prototype.getOwnerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOwnerAddress()));
};


/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateAssetContract.prototype.getOwnerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOwnerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateAssetContract} returns this
 */
TronWebProto.UpdateAssetContract.prototype.setOwnerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes description = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateAssetContract.prototype.getDescription = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes description = 2;
 * This is a type-conversion wrapper around `getDescription()`
 * @return {string}
 */
TronWebProto.UpdateAssetContract.prototype.getDescription_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescription()));
};


/**
 * optional bytes description = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescription()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateAssetContract.prototype.getDescription_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescription()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateAssetContract} returns this
 */
TronWebProto.UpdateAssetContract.prototype.setDescription = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes url = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateAssetContract.prototype.getUrl = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes url = 3;
 * This is a type-conversion wrapper around `getUrl()`
 * @return {string}
 */
TronWebProto.UpdateAssetContract.prototype.getUrl_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUrl()));
};


/**
 * optional bytes url = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUrl()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateAssetContract.prototype.getUrl_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUrl()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateAssetContract} returns this
 */
TronWebProto.UpdateAssetContract.prototype.setUrl = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 new_limit = 4;
 * @return {number}
 */
TronWebProto.UpdateAssetContract.prototype.getNewLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.UpdateAssetContract} returns this
 */
TronWebProto.UpdateAssetContract.prototype.setNewLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 new_public_limit = 5;
 * @return {number}
 */
TronWebProto.UpdateAssetContract.prototype.getNewPublicLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.UpdateAssetContract} returns this
 */
TronWebProto.UpdateAssetContract.prototype.setNewPublicLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.toObject = function(opt_includeInstance) {
  return TronWebProto.ParticipateAssetIssueContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!TronWebProto.ParticipateAssetIssueContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.ParticipateAssetIssueContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerAddress: msg.getOwnerAddress_asB64(),
    toAddress: msg.getToAddress_asB64(),
    assetName: msg.getAssetName_asB64(),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.ParticipateAssetIssueContract}
 */
TronWebProto.ParticipateAssetIssueContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new TronWebProto.ParticipateAssetIssueContract;
  return TronWebProto.ParticipateAssetIssueContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.ParticipateAssetIssueContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.ParticipateAssetIssueContract}
 */
TronWebProto.ParticipateAssetIssueContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOwnerAddress(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setToAddress(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAssetName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  TronWebProto.ParticipateAssetIssueContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.ParticipateAssetIssueContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.ParticipateAssetIssueContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getToAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getAssetName_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getOwnerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getOwnerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOwnerAddress()));
};


/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getOwnerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOwnerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.ParticipateAssetIssueContract} returns this
 */
TronWebProto.ParticipateAssetIssueContract.prototype.setOwnerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes to_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getToAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes to_address = 2;
 * This is a type-conversion wrapper around `getToAddress()`
 * @return {string}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getToAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getToAddress()));
};


/**
 * optional bytes to_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getToAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getToAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.ParticipateAssetIssueContract} returns this
 */
TronWebProto.ParticipateAssetIssueContract.prototype.setToAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes asset_name = 3;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getAssetName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes asset_name = 3;
 * This is a type-conversion wrapper around `getAssetName()`
 * @return {string}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getAssetName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAssetName()));
};


/**
 * optional bytes asset_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAssetName()`
 * @return {!Uint8Array}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getAssetName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAssetName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.ParticipateAssetIssueContract} returns this
 */
TronWebProto.ParticipateAssetIssueContract.prototype.setAssetName = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
TronWebProto.ParticipateAssetIssueContract.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!TronWebProto.ParticipateAssetIssueContract} returns this
 */
TronWebProto.ParticipateAssetIssueContract.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


goog.object.extend(exports, TronWebProto);
